import { useState, useRef } from 'react';
import Icon from 'components/Icon';
import Media from 'components/Media';
import useClickOutside from '../../lib/hooks/useClickOutside';
import classNames from 'classnames';
import Parse from 'react-html-parser';

const Dropdown = ({
    data,
    icon,
    className,
    onSelectChange = null,
    objectData,
    preSelected,
}) => {
    const getChoice = () => {
        let choice = null;
        if (data) {
            choice = data.length > 0 ? data[0] : null;
        }
        if (objectData) {
            choice =
                objectData.length > 0
                    ? preSelected
                        ? objectData.find((item) => item.id === preSelected)
                        : objectData[0]
                    : null;
        }
        return choice;
    };
    const [choice, setChoice] = useState(getChoice);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleChoice = (e, item) => {
        e.preventDefault();
        setChoice(item);
        setIsOpen(false);
        onSelectChange && onSelectChange(item);
    };

    const toggleObjectChoice = (e, item) => {
        e.preventDefault();
        setChoice(item);
        setIsOpen(false);
        onSelectChange && onSelectChange(item.id);
    };

    const handletriggerKeys = (e) => {
        {
            if (e.key === 'Enter') {
                toggleDropdown;
            }
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                if (isOpen) {
                    e.target.nextSibling.firstChild.focus();
                }
            }
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (isOpen) {
                    e.target.nextSibling.lastChild.focus();
                }
            }
        }
    };

    const handleMenuKeys = (e) => {
        {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                if (e.target.nextSibling) {
                    e.target.nextSibling.focus();
                } else {
                    e.target.parentNode.previousSibling.focus();
                }
            }
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (e.target.previousSibling) {
                    e.target.previousSibling.focus();
                } else {
                    e.target.parentNode.previousSibling.focus();
                }
            }
        }
    };

    const ref = useRef();
    const { isOpen, setIsOpen } = useClickOutside(ref);
    return (
        <>
            <div
                className={classNames('dropdown', {
                    [`${className}`]: className,
                })}
                ref={ref}
            >
                <button
                    className="dropdown__trigger"
                    onClick={toggleDropdown}
                    onKeyDown={handletriggerKeys}
                >
                    <div className="dropdown__selection">
                        {data && choice && choice}
                        {data && !choice && 'Select...'}
                        {objectData && icon && (
                            <div key={Date.now()}>
                                <Media
                                    className="dropdown__icon"
                                    media={choice.media}
                                    allowIcon
                                />
                            </div>
                        )}
                        {objectData && choice && Parse(choice.title)}
                    </div>
                    <div className="dropdown__arrow" key={Date.now()}>
                        {isOpen ? (
                            <Icon icon={'fa-solid fa-chevron-up'} />
                        ) : (
                            <Icon icon={'fa-solid fa-chevron-down'} />
                        )}
                    </div>
                </button>
                {isOpen && (
                    <div className="dropdown__menu">
                        {data &&
                            data.map((item, index) => {
                                return (
                                    <a
                                        href="!#"
                                        className="dropdown__item"
                                        key={index}
                                        onClick={(e) => toggleChoice(e, item)}
                                        onKeyDown={handleMenuKeys}
                                    >
                                        {icon && (
                                            <Icon
                                                icon={icon}
                                                className="dropdown__icon"
                                            />
                                        )}
                                        {item}
                                    </a>
                                );
                            })}
                        {objectData &&
                            objectData.map((item, index) => {
                                return (
                                    <a
                                        href="!#"
                                        className="dropdown__item"
                                        key={index}
                                        onClick={(e) =>
                                            toggleObjectChoice(e, item)
                                        }
                                    >
                                        {icon && (
                                            <Media
                                                className="dropdown__icon"
                                                media={item.media}
                                                allowIcon
                                            />
                                        )}
                                        {Parse(item.title)}
                                    </a>
                                );
                            })}
                    </div>
                )}
            </div>
        </>
    );
};

export default Dropdown;
